// color-palette
:root {
  --light-grey-blue: #aeb3be;
  --slate-blue: #5c6d99;
  --hint: #36425d;
  --white: #ffffff;
  --side-bar-selected: #3b6cb9;
  --dark-background: #171f2f;
  --darkish-pink: #d74471;
  --inactive-button: #181c2e;
  --dark-blue-grey: #142342;
  --card: #242e45;
  --blue-grey: #7b87a3;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext');

//@font-face {
//    font-family: OpenSans;
//    src: local(OpenSans-Light),
//    url(/path/to/OpenSans-Light.woff2) format("woff2"),
//    url(/path/to/OpenSans-Light.woff) format("woff"),
//    url(/path/to/OpenSans-Light.ttf) format("truetype");
//    font-weight: 300;
//    font-style: normal;
//    font-stretch: normal;
//}
//
//@font-face {
//    font-family: OpenSans;
//    src: local(OpenSans-Regular),
//    url(/path/to/OpenSans-Regular.woff2) format("woff2"),
//    url(/path/to/OpenSans-Regular.woff) format("woff"),
//    url(/path/to/OpenSans-Regular.ttf) format("truetype");
//    font-weight: normal;
//    font-style: normal;
//    font-stretch: normal;
//}
//
//@font-face {
//    font-family: OpenSans;
//    src: local(OpenSans-SemiBold),
//    url(/path/to/OpenSans-SemiBold.woff2) format("woff2"),
//    url(/path/to/OpenSans-SemiBold.woff) format("woff"),
//    url(/path/to/OpenSans-SemiBold.ttf) format("truetype");
//    font-weight: 600;
//    font-style: normal;
//    font-stretch: normal;
//}
//@import 'color-pallete';

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  max-width: 100%;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  * {
    box-sizing: border-box;
    outline: none;
  }

  #root {
    height: 100%;
  }
}

.visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.button-reset {
  padding: 0;

  color: inherit;
  font: inherit;

  background-color: transparent;
  border: none;
  cursor: pointer;
}

// цвет автоподстановок
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  //
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--white);
}
